const ariaHandlers = {
	btnClick: function (event) {
		this.toggleButton(event.target);
	},

	btnKeyPress: function (event) {
		if (event.keyCode === 32 || event.keyCode === 13) {
			event.preventDefault();
			this.toggleButton(event.target);
		}
	},

	toggleButton: function (element) {
		let pressed = (element.getAttribute("aria-pressed") === "true");
		element.setAttribute("aria-pressed", !pressed);
		element.classList.toggle("is-active");
	}
}
